export * from './risk-aml-summary/risk-aml-summary.component';
export * from './datasource-listing/datasource-listing.component';
export * from './aml-summary-details/aml-summary-details.component';
export * from './data-unavailable/data-unavailable.component';
export * from './new-aml-results/new-aml-results.component';
export * from './risk-screening-guage-chart/risk-screening-guage-chart.component';
export * from './risk-screening-table/risk-screening-table.component';
export * from './risk-policy/risk-policy.component';
export * from './questionaire/questionaire.component';
export * from './entity-details-page/entity-details-page.component';
export * from './address-verification/address-verification.component';
export * from './liveliness-verification/liveliness-verification.component';
export * from './selfie-verification/selfie-verification.component';
export * from './all-doc-upload/all-doc-upload.component';
