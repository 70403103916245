import { Injectable } from '@angular/core';
import { Router , CanActivate , ActivatedRouteSnapshot , RouterStateSnapshot } from '@angular/router';
import { Observable , of } from "rxjs";
import { DataService } from '../services/data.service';

@Injectable()
export class DeGuard implements CanActivate {
    constructor(
        private _router: Router, 
        private data   : DataService
    ) {}
    
    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot):Observable<boolean>{
        let token = this.data.getCookiesByName('master_token');
        this.data.changeToken(token);
        console.log(this._router.url);
        console.log(state.url);
        var currentUrl = state.url;
        let current = currentUrl.substring(0, currentUrl.indexOf("?")).length
          ? currentUrl.substring(0, currentUrl.indexOf("?"))
          : currentUrl;
          
        if(token){
            // this._router.navigate(['/home']);
            if(current == "/payment"){
                this._router.navigate(['/invoices']);
            }
            else{
                this._router.navigate(['/home']);
            }
            return of(false);
        } else {
            return of(true);
        }
    }
}