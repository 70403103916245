import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { LegalOnboardingService } from '../../../app/pages/kyb-onboarding/services/legal-onboarding.service';

@Component({
  selector: 'app-liveliness-verification',
  templateUrl: './liveliness-verification.component.html',
  styleUrls: ['./liveliness-verification.component.scss']
})
export class LivelinessVerificationComponent implements OnInit {
  @Input() item;
  frontImage: SafeUrl;
  extractionResult: any;
  livelinessArray: any = [];

  constructor(private domSanitizer: DomSanitizer, private legalService: LegalOnboardingService) { }

  ngOnInit(): void {
    var video = document.querySelector('video');
		video.style.display = "none";
		this.legalService.getLivelinessVideo(this.item.docPathF)
		.subscribe(
			(data)=>{
				const blob = new Blob([data], { type: 'video/mp4' });
				var file = new File([blob], "hero.mp4", {type:"video/mp4"});
				var video = document.querySelector('video');
				if(video){
					video.style.display = "block";
        			video["src"] = window.URL.createObjectURL(blob);
				}
        // else{
        //   video["src"] = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4";
        // }
			}
		);

    // Extraction Results
    this.extractionResult = JSON.parse(this.item.extractionResult);
    if(this.extractionResult){
      console.log(this.extractionResult);
      this.livelinessArray = this.extractionResult['livenessRes']
    }
  }

}
