import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { 
  AmlSummaryDetailsComponent,
  DatasourceListingComponent,
  DataUnavailableComponent,
  RiskAmlSummary,
  NewAmlResultsComponent,
  RiskScreeningGuageChartComponent,
  RiskScreeningTableComponent,
  RiskPolicyComponent,
  QuestionaireComponent,
  EntityDetailsPageComponent,
  AddressVerificationComponent,
  LivelinessVerificationComponent,
  SelfieVerificationComponent,
  AllDocUploadComponent
} from '../index';
import { IdVerificationComponent } from '../id-verification/id-verification.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShowImageModule } from "../../directives/show-image/show-image.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PipesModule } from '../../../app/pipes/pipes.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    AmlSummaryDetailsComponent,
    RiskAmlSummary,
    DatasourceListingComponent,
    DataUnavailableComponent,
    NewAmlResultsComponent,
    RiskScreeningGuageChartComponent,
    RiskScreeningTableComponent,
    QuestionaireComponent,
    RiskPolicyComponent,
    EntityDetailsPageComponent,
    AddressVerificationComponent,
    IdVerificationComponent,
    SelfieVerificationComponent,
    LivelinessVerificationComponent,
    AllDocUploadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ShowImageModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgxChartsModule,
    PipesModule,
    PaginationModule.forRoot(),
    NgxPaginationModule
  ],
  exports: [
    AmlSummaryDetailsComponent,
    RiskAmlSummary,
    DatasourceListingComponent,
    DataUnavailableComponent,
    NewAmlResultsComponent,
    RiskScreeningGuageChartComponent,
    RiskScreeningTableComponent,
    QuestionaireComponent,
    RiskPolicyComponent,
    EntityDetailsPageComponent,
    AddressVerificationComponent,
    IdVerificationComponent,
    SelfieVerificationComponent,
    LivelinessVerificationComponent,
    AllDocUploadComponent
  ],
})
export class ComponentModuleModule { }
