import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-data-unavailable',
  templateUrl: './data-unavailable.component.html',
  styleUrls: ['./data-unavailable.component.scss']
})
export class DataUnavailableComponent implements OnInit {
  @Input() imgUrl: string;
  @Input() heading: string;
  @Input() subheading?: any;
  @Input() isButton: boolean = false;
  @Input() isButtonIcon: boolean = false;
  @Input() buttonIconUrl?: any;
  @Input() btnText?: string;
  @Output() close = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  closeInfo(event: MouseEvent){
    event.preventDefault();
    this.close.emit();
  }

}
