import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LegalOnboardingService } from '../../../app/pages/kyb-onboarding/services/legal-onboarding.service';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-entity-details-page',
  templateUrl: './entity-details-page.component.html',
  styleUrls: ['./entity-details-page.component.scss','../../../../src/app/pages/risk-screening/risk-screening.component.scss']
})
export class EntityDetailsPageComponent implements OnChanges {
  @Input() entityObject: any;
  accountId: any;
  docInfo: any;
  score$: Observable<number>;
  riskData: any;
  idvScoreStatus: any;
  riskScreening: any[] = [];
  companyAml: any;
  companyInfo: any;
  verifiedUBOs: number = 0;
  uboList: number = 0;
  verifiedDocs: number = 0;
  checkListEntries: any;
  anyOfficerFound: boolean;
  numberData: number;
  checkListArray: any = [];

  constructor(
    private legalServices: LegalOnboardingService,
    private dataService: DataService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.entityObject?.currentValue){
      this.entityObject = changes?.entityObject?.currentValue
    }
    if(this.entityObject) this.entityObject = JSON.parse(this.entityObject)
    if(this.entityObject?.kysAmlSummary) this.companyAml = this.entityObject?.kysAmlSummary;
    if(this.entityObject?.addedEntity && this.entityObject?.addedEntity?.account?.accountId){
      this.accountId = this.entityObject?.addedEntity?.account?.accountId;
      this.getUboList();
      this.getDocumentsInfo();
      this.getRiskScore(this.accountId);
      this.getCompanybasicDetails();
      this.getCompanyChecks();
    }
  }

  getCompanyChecks(){
    this.legalServices.getCheckList(this.accountId).subscribe((res) => {
      if (res !== null && res !== undefined) {
        this.checkListEntries = res.entries;
        this.anyOfficerFound = true;
      }
      for(let item of this.checkListEntries){
        this.numberData += 1;
        this.getIndexValue(this.numberData-1);
      }
    }); 
  }

  getIndexValue(i){
    if(this.checkListArray.indexOf(i) > -1){
      this.checkListArray.splice(this.checkListArray.indexOf(i), 1)
    }
    else{
      this.checkListArray.push(i)
    }    
  }

  getCompanybasicDetails(){
    this.legalServices.getCorporateAccountInfo(this.accountId).subscribe((res) => {
      this.companyInfo = res;
    });
  }

  getDocumentsInfo(){
    this.verifiedDocs = 0;
    this.legalServices.getDocList(this.accountId).subscribe((res) => {
      let response  = res || [];
      response.forEach(item => {
         if(item?.status == 'VERIFIED'){
          this.verifiedDocs += 1;
        }
      })
    });
    this.score$ = this.legalServices.getOverAllScore(this.accountId);
    this.legalServices.getDocumentsInfo(this.accountId).subscribe((res) => {
      res ? this.docInfo = res : '';
    });
  } 

  getUboList(){
    this.legalServices.getManualUbo(this.accountId).subscribe((res) => {
      this.uboList = res?.length || 0;
      this.riskScreening = res || [];
      const promises = this.riskScreening.map(async (e) =>{
        const amlData = await this.getRiskScreening(e?.id);
        const adverseData = await this.getAdverseMediaStatus(e?.tpe?.kysNo);
        let isSantionFound: boolean = false;
        let isCrimeFound: boolean = false;
        let amlRiskScore: number = 0;
        let adverseMediaScore: number =0;
        if(amlData){
          if(amlData?.aml){
            isSantionFound = amlData?.aml?.SANCTION ? amlData?.aml?.SANCTION : amlData?.aml?.WATCHLIST;
            isCrimeFound = amlData?.aml?.CRIME;
          }
          if(amlData?.uboRiskScore?.data && amlData?.uboRiskScore?.data?.content?.length && amlData?.uboRiskScore?.data?.content[0]?.riskProfile){
            amlRiskScore = amlData?.uboRiskScore?.data?.content[0]?.riskProfile?.score
          }
          else if(amlData?.score){
            amlRiskScore = amlData?.score;
          }
        }
        if(adverseData?.riskScore){
          adverseMediaScore = adverseData?.riskScore;
        }
        return {
          ...e, isSantionFound, isCrimeFound, amlRiskScore, adverseMediaScore
        }
      });
      Promise.allSettled(promises).then((res) => {
        this.riskScreening = res.map((f) => f['value'])
      });
      if(res?.length){
        for(let item of res){
          if(item?.tpe?.kysNo){
            this.legalServices.getAMLStatus(item?.tpe?.kysNo).subscribe((status) => {
              if(status == 'LOW'){
                this.verifiedUBOs += 1;
              }
            })
          }
        }
      }
    });
  }

  async getRiskScreening(id){
    this.dataService.changeLoaderVisibility(true);
    const amlData = await this.legalServices.getRiskScreening(id).toPromise();
    this.dataService.changeLoaderVisibility(false);
    return amlData;
  }

  async getAdverseMediaStatus(id){
    this.dataService.changeLoaderVisibility(true);
    const adverseData = await this.legalServices.getAdverseMediaStatus(id).toPromise();
    this.dataService.changeLoaderVisibility(false);
    return adverseData;
  }

  getRiskScore(accountId){
    this.dataService.changeLoaderVisibility(true);
    this.legalServices.getLegalRiskScore(accountId).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      if (res) {
        this.riskData = res;
      }
    });
    this.legalServices.getOverallStatus(accountId).subscribe((res) => this.idvScoreStatus = res);
  }

}
