import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientListComponent } from './client-list/client-list.component';
import { UserAreaComponent } from './user-area/user-area.component';
import { UserReportComponent } from './user-report/user-report.component';

const routes: Routes = [
  { path: 'list', component: ClientListComponent },
  {path: 'user-area/:id',component:UserAreaComponent},
  { path:'user-report/:name',component:ClientListComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DevOpsRoutingModule { }
