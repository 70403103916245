import { Component, Input, OnInit,Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { DataService } from '../../services/data.service';
import { QuestionaireService } from '../../services/questionaire.service';


@Component({
  selector: 'app-questionaire',
  templateUrl: './questionaire.component.html',
  styleUrls: ['./questionaire.component.scss','../../../../src/app/pages/risk-screening/risk-screening.component.scss'],
  providers: [DatePipe]
})
export class QuestionaireComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() kysId;
  questionaireSubmited:boolean=false;
  currentDate:any;
  currentTime:any;
  questionnairDetails:any;
  questionaireArr=[
  ];
  option:any=["","","",""]
  questionType:any;
  questionairName:any="";
  editableItem:any;
  editableQueItem:any;
  selectedQuestionaire:any;
  addStep=1;
  questionTempArr=[]
  selectTempQuestion:any='';
  public bsModalRef: BsModalRef;
  answersLength: number = 0;
  tempStart=1;
  pos:string="showQuestionnaireList"
  searchKey='';
  searchQuestion=''
  questionnaire='';
  frequencyList=[
    {id:0, name:"Does not repeats"},
    {id:1, name:"Daily"},
    {id:2, name:"Weekly"},
    {id:3, name:"Monthly"},
    {id:4, name:"Yearly"}
  ]
  categoryList=[{},{id:"Healthcare_Details",title:"Healthcare Details"},{id:"Identification_Of_Client",title:"Identification Of Client"},{id:"Client_Bank_Acct_Details",title:"Client Bank Acct Details"},{id:"Accreditations_And_Certificates",title:"Accreditations And Certificates"},
  {id:"Quality_And_Control_Systems",title:"Quality And Control Systems"},{id:"Human_Rights_And_Fair_Working_Conditions",title:"Human Rights And Fair Working Condition"},{id:"Environmental_Responsibility_And_Sustainability",title:"Environmental Responsibility And Sustainability"},
  {id:"Business_Integrity",title:"Business Integrity"},{id:"Export_Control",title:"Export Control"},{id:"Intellectual_Property_Rights_Trade_Secrets_And_Data_Protection",title:"Intellectual Property Rights Trade_Secrets And Data Protection"},{id:"Suppliers_Commitment_And_Contractual_Obligation",title:"Suppliers Commitment And Contractual Obligation"},
  {id:"Reporting_Monitoring_And_Sanctioning",title:"Reporting Monitoring And Sanctioning"},{id:"Trade_Checks",title:"Trade Checks"}]
  frequency:any=this.frequencyList[0].id;
  recuring:boolean=false;
  allQuestionnaire: any;
  questionnairDetailsVal: boolean=false;
  totaltemp=0;
  byCategory='';
  constructor(private modalService: BsModalService,private datePipe: DatePipe,
    private data:DataService, private qs: QuestionaireService){
    let date=new Date();
    this.currentDate = this.datePipe.transform(date, 'yyyy-MM-dd'); 
    this.currentTime = this.datePipe.transform(date, 'h:mm a')
  }

  ngOnInit(): void {
    this.getStandardQuestions()
    //this.getQuestionnaire();
    this.getAllQuestionnaireList(this.tempStart)
  }
  getStandardQuestions(){
    this.byCategory='';
    this.qs.getStandardQuestions().subscribe(result=>{
      this.questionTempArr = result;
    })
  }
  getQuestionaireDetails(){
    this.qs.getQuestionaireDetails(this.questionnairDetails.questionnairId).subscribe((res:any)=>{
      this.questionaireArr=res.data;
      this.questionnairDetailsVal=true;
      this.pos='questionList';
    })
  }
  changeState(val){
    this.pos=val;
    if(val=='showQuestionnaireList'){
      this.getAllQuestionnaireList(this.tempStart);
    }
  }
  getAllQuestionnaireList($event){
    this.tempStart=$event
   
    if(this.searchKey.length>0){
      this.filterBySearch(this.tempStart,10)
    }
    else{
      let payload={"page":this.tempStart-1,"size":10}
      this.qs.getAllQuestionaireDetails(payload).subscribe((res:any)=>{
        this.allQuestionnaire=res.data.content;
        this.totaltemp=res.data.totalElements;
      })
    }
  }
  getQuestionnaire(){
    this.questionaireArr=[];
    this.qs.getQuestionnaire(this.kysId).subscribe(result=>{
      if(result.length>0){
        let data ={
            id:0,
            name:"Questionaire 1",
            questions:[]
        }
        result.forEach(element => {
          data.questions.push(element);
        });
        this.questionaireArr.push(data);
        this.questionaireSubmited=true;
      }
    });
  }

  

  textQuestions(answers,  j){
    this.questionaireArr[j].answersLength = answers.length
  }

  addItemToEdit(item,type){  
    if(type=="qa")
      this.editableItem=item;
    if(type=="que")
      this.editableQueItem=item;
  }

  addQuestionaire(name){
    let payload={
      questionnairName: name
    }
    this.qs.createQuestioniare(payload).subscribe((res:any)=>{
      this.questionnairDetails=res.data;
      this.getQuestionaireDetails()
    })
  }
  addQuestionToTemplate(questionId){
    this.qs.addQuestionTotemplate(questionId,this.questionnairDetails.questionnairId).subscribe(res=>{
      this.getQuestionaireDetails()
    })
  }
  addQuestionnaireDetails(id){
    this.questionnairDetails=this.allQuestionnaire.find(({questionnairId})=>questionnairId==id);
    this.getQuestionaireDetails()
  }
  addQuestion(question, questionType){
    if(question!=''){
      let obj={
        "type": "CUSTOM",
        "question": question,
        "description": question,
        "inputType": questionType,
        "outputChiceList": questionType=='DROP_DOWN'?["YES","NO"]:questionType=='MULTIPLE_CHOICE'?this.option:[], 
        "questionType": questionType == 'FILE' ? 'DOCUMENT' : "UNDEFINED"
      }
      this.qs.addCustomQuestion(obj,this.questionnairDetails.questionnairId).subscribe(res=>{
        this.getQuestionaireDetails()
      })
      this.questionaireArr.push(obj);
      this.pos="questionList"
    }
  }
  clearForm(){
    this.questionType=null;
    this.option=["","","",""]
  }
  editQuestionaireValues(value){
    if(value!=""){
      console.log("entered edit");
      this.questionaireArr.find(item=>item.id==this.editableItem.id)["name"]=value;

      console.log("exited edit");
    }
  }

  

  deleteSelectedQuestion(){
    let qaindex = this.questionaireArr.findIndex(item=>item.kysQesID == this.selectedQuestionaire.kysQesID);//check delete index 0 issue
    let index;
    index=this.questionaireArr[qaindex].kysQesID;
      this.qs.deleteQuestion(index).subscribe(res=>{
        this.getQuestionaireDetails();
      })
  }

  editQuestion(value,type){
    if(type=='editTemp'){
      this.questionTempArr.find(item=>item.kysQesID == this.editableQueItem.kysQesID)["question"]=value;
      this.addStep=1;
    }
    if(type=='edit'){
      let qaindex = this.questionaireArr.findIndex(item=>item.id == this.selectedQuestionaire.id);
      if(this.editableQueItem.kysQesID!=undefined){
        this.questionaireArr[qaindex]["questions"].find(item=>item.kysQesID == this.editableQueItem.kysQesID)["question"]=value;
      }
      else if(this.editableQueItem.questionnaire.kysQesID!=undefined){
        this.questionaireArr[qaindex]["questions"].find(item=>item.questionnaire.kysQesID == this.editableQueItem.questionnaire.kysQesID)["question"]=value;
      }
    }
  }
  
  discardQuestionaire(id){
    let index = this.questionaireArr.findIndex(item=>item.id == id);
    this.questionaireArr.splice(index,1);
  }

  submitQuestionaire(){
    this.newItemEvent.emit(this.questionnairDetails.questionnairId);
    this.data.questionnaireIdVal(this.questionnairDetails.questionnairId)
  }

  submitAndSend(){
    this.data.changeLoaderVisibility(true);
    let body={
      questionId:[],
      remoteCheck:true  
    }
    this.questionaireArr[0].questions.forEach(element => {
      if(element.kysQesID!=undefined)
        body.questionId.push(element.kysQesID);
      else if(element.questionnaire.kysQesID!=undefined)
        body.questionId.push(element.questionnaire.kysQesID);
    });
    this.qs.submitQuestionaire(this.kysId, body).subscribe(result=>{
      this.data.changeLoaderVisibility(false);
      // this.questionaireSubmited=true;
      this.getQuestionnaire();
    })
  }

  saveQuestionaireTemp(){

  }


  toggleDropdown(index){
    let id="moreDropdown"+index
    document.getElementById(id).classList.toggle("show");
  }
  getQuestionByCategory(filter){
    this.byCategory = filter;
    this.searchQuestion='';
    this.qs.getQuestionByCategory(this.byCategory).subscribe(res=>{
      this.questionTempArr = res.content;
    })
  }
  filterBySearch(page,size){
    let payload={
      page:page,size:size,name:this.searchKey
    }
    if(this.searchKey.length>0){
      this.qs.getTemplateSearch(payload).subscribe(res=>{
        this.allQuestionnaire=res.content;
        this.totaltemp=res.totalElements;
      })
    }else{
      this.getAllQuestionnaireList(this.tempStart)
    }

  }
  filterQuestionBySearch(page,size){
   if(this.byCategory!=undefined && this.byCategory!='' && this.searchQuestion.length>0){
     let payload={page:0,size:10,name:this.searchQuestion,questionCategory:this.byCategory}
     this.qs.searchQuestionByCategory(payload).subscribe(res=>{
      this.questionTempArr = res.content;
     })
   }
  }
}
