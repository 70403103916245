// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  csrfToken: false, // It will always be false for dev
  apiUrl: 'https://stage.api.kychub.com',
  masterUrl: "https://api.app.kychub.com",
  basicAuthToken: 'NWZlNTk3NWRlMzE3YmQ4MmNkMTEzMDQ1ZTExYzk5OTM1ZjBkNmI3YTA3N2EzMGRlYjg2MzliOTMyOTY2M2UzZjplZjM4Mjk1NTg0ZTJiMjNiMmY4YzU0OGFjODFmYTNhODM3NmJlMTUxYzdkNmQ0OWY5OTg1ZjEwNzgwNTdjMzM5',
  masterAuthToken: 'N2VmMzdkMGUxZjMzYWU2ZTkwMzdhZmYwY2IyZTNhMTgzMjE3OTYzOTNjODNlMDhiMmYwNTM3YzE5YjY5NjI2NjoxYmQ2Y2Q1MTdhMDUwZGEyMWY4MDQ1Njk0MWFiMTU0NjU2MDhlZjFmMTNmYWQ1MzA5NTc5ZDg5NGFmYTc3OWFm',
  firebase: {
    apiKey: "AIzaSyDXJqmgdZy8GgMncHvijeEHfDsC_0u3yqg",
    authDomain: "kychub-cd897.firebaseapp.com",
    databaseURL: "https://kychub-cd897.firebaseio.com",
    projectId: "kychub-cd897",
    storageBucket: "",
    messagingSenderId: "739715708057",
    appId: "1:739715708057:web:2f59573e01be65a8"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
