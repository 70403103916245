import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RiskScreeningService } from '../../../../src/app/pages/risk-screening/risk-screening.service';

@Component({
  selector: 'app-datasource-listing',
  templateUrl: './datasource-listing.component.html',
  styleUrls: ['./datasource-listing.component.scss', '../../pages/risk-screening/risk-screening.component.scss']
})
export class DatasourceListingComponent implements OnChanges {
  @Input() dataSourceList;
  @Input() category;
  // @Input() country;
  // @Input() globalSearch;
  dataSourceName : any[] = [
    {
      slug: 'pep',
      list: [
        { name: "Every politicians" },
        { name: "House of commons" },
        { name: "List of world leaders" },
        { name: "FRANCE SENATE LIST" },
        { name: "United Kingdom Government People" },
        { name: "Nevada Attorney General" },
        { name: "UNOPS leaders" },
        { name: "US SECURITIES AND EXCHANGE COMMISSION COMMISSIONERS" },
        { name: "Presidents of the Senate" },
        { name: "Ministers of South Africa" },
        { name: "Japan Ministers List" },
        { name: "Minister of Law and Justice" },
        { name: "Senate Of US State Wise" },
        { name: "Prime Ministers Office Singapore Cabinet Ministers" },
        { name: "List Of 11th Parliament members of Bangladesh" },
        { name: "Federal Ministers Russian Government" },
        { name: "House of Lords" }
      ]
    },
    {
      slug: 'watchlist',
      list: [
        {name: 'Nyse Disciplinary Actions'},
        {name: '2019 Delinquent Child Support List'},
        {name: 'Occ Enforcement Action'},
        {name: 'Data Mega Store'},
        {name: 'EBRD'},
        {name: 'Canada Alberta Securities Commission Decisions And Orders'},
        {name: 'British Columbia Securities Commission Notices Of Hearing And Temporary Orders'},
        {name: 'Security Commission Malaysia Investor Alert List'},
        {name: 'Osc Tribunal Proceedings'},
        {name: 'Monetory Authority Of Singapore Investor Alert List'},
        {name: 'Statutorily Debarred Parties'}
      ] 
    },
    {
      slug: 'sanction',
      list: [
        { name: 'EU Sanctions' },
        { name: 'UK HM treasury' },
        { name: 'US department of Treasury' },
        { name: 'OFAC' },
        { name: 'SECO Sanctions List' },
        { name: 'UK SANCTIONS ON RUSSIAN BANKS AND INDIVIDUALS' },
        { name: "OFAC Lists" },
        { name: "World Bank Ineligible Firms Individuals" },
        { name: "UK Sanctions List Publication" },
        { name: "US Treasury Designated Russian Oligarchs" },
        { name: "EU Sanction Individual 09102021" },
        { name: "UK HM Treasury New" },
        { name: "Ukraine Russia related Sanctions" },
        { name: "Canada Economic Measures Russia-1" },
        { name: "Canada Economic Measures Russia-2" },
        { name: "UK HM Treasury" },
        { name: "EU Sanction" },
        { name: "US Department Of The Treasury SDN" },
        { name: "Interpol UN Notices Entities" },
        { name: "SECO" },
        { name: "UK GOV SANCTIONS" },
        { name: "CIBIL's List of Wilful Defaulters" },
        { name: "FinCEN 311 Special Measures" },
        { name: "Willful Defaulters" },
        { name: "Suspended and Blacklisted NGOs" },
        { name: "Tax Defaulters Individuals" }
      ]
    },
    {
      slug: 'crime',
      list: [
        { name: "Interpol Public Red Notices" },
        { name: "DEA'S MOST WANTED" },
        { name: "FBI Wanted Terrorists" },
        { name: "ICAC Wanted Persons" },
        { name: "US Department of Transportation" },
        { name: "U.S. Department of Justice" },
        { name: "INTERPOL RED NOTICE" },
        { name: "Singapore Terrorism Suppression of Financing Act Order 2015" },
        { name: "ATF Most Wanted" }
      ]
    },
    {
      slug: 'sip',
      list: [
        { name: "List Issued By Fcnb Agent" },
        { name: "Richest Models" },
        { name: "Richest Authors" },
        { name: "Monetory Authority Of Singapore Payments" },
        { name: "Federal Deposit Insurance Corporation Members" },
        { name: "Hudson County Most Wanted" },
        { name: "List Of Corporate Headquarters In Mbabane" },
        { name: "The Most Famous Celebrity Born Every Year Since 1975" },
        { name: "Fcnb Mortage Brokers And Associates" },
        { name: "Swazi Celebrities" }
      ]
    },
    {
      slug: 'sie',
      list: [
        { name: "List Of Companies That Do Or Do Not Exploit Animals" },
        { name: "Monetory authority of singapore Capital Markets" },
        { name: "Bermuda Monetory Authority Licensed Entities" },
        { name: "Office of the superintendent of financial institution regulated Organizations" },
        { name: "British virgin islands Regulated Entities Insurance" },
        { name: "Banks Incorporated In England" },
        { name: "FCNB insurer company" },
        { name: "Monetory authority of singapore Financial Institutions Banking" },
        { name: "New Zealand Exchange List of Companies" },
        { name: "Nigeria List of Government Owned Companies" }
      ]
    },
    {
      slug: 'si',
      list: [
        { name: "Financial Supervisory Authority, Iceland (FME)" },
        { name: "CBIC Court Cases Organisations" },
        { name: "List Of Airlines Of The United Arab Emirates" },
        { name: "British virgin islands Registered TradeMark Agents" },
        { name: "Nigeria List of Airlines" },
      ]
    }
  ];
  topData:any=[];
  constructor(private _rs: RiskScreeningService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dataSourceList?.['currentValue'] && changes.category?.['currentValue']){
      const response = JSON.parse(changes['dataSourceList']['currentValue']);
      this.dataSourceName = this.dataSourceName.filter((e) => changes['category']['currentValue'].toLowerCase() == e.slug)
      this.dataSourceName[0].list.forEach((e) => {
        if(response.map((e) => e.toLowerCase()).includes(e.name.toLowerCase())){
          e['found'] = true;
        }
        else{
          e['found'] = false;
        }
      });

    } 
    // if(this.category && this.country !== undefined){
    //   this.getTopDataSourcesAml()
    // }
  } 
  // getTopDataSourcesAml(){
  //   const payload = {

  //     "category": this.category.toLowerCase(),
  
  //     "country": this.category.toLowerCase() === 'sanction' ? null : this.country,
  
  //     "global": this.category.toLowerCase() === 'sanction' ? true : this.globalSearch
  
  //   }
  //   this._rs.getTopDataSource(payload).subscribe((response) => {if(response) {
  //     this.topData = response
  //   }
  // });
    
  // }

}
