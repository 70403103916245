import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KycModalComponent } from '../../shared/kyc-modal/kyc-modal.component';

@Component({
  selector: 'app-risk-screening-guage-chart',
  templateUrl: './risk-screening-guage-chart.component.html',
  styleUrls: ['./risk-screening-guage-chart.component.scss','../../../../src/app/pages/risk-screening/risk-screening.component.scss']
})
export class RiskScreeningGuageChartComponent implements OnChanges {
  @Input() riskProfileData;
  public canvasWidth = 300
  public needleValue = 0
  public bottomLabel = '0';
  public options = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#17B18C', '#ED7C15','#D84040'],
      arcDelimiters: [30,50],
      rangeLabel: ['0', '100'],
      needleStartValue: 0,
  }
  overallStatus: any;
  public bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['riskProfileData']['currentValue']){
      const score = changes['riskProfileData']['currentValue'].score.toFixed(2);
      this.needleValue = score;
      this.bottomLabel = String(score);
      this.overallStatus = changes['riskProfileData']['currentValue'].label;
    }
  }

  getCRRModel(){
    if(this.riskProfileData.riskFactor){
      this.riskProfileData.riskFactor.forEach((item) => {
        if(item.key.includes('Aml')){
          item.key = item.key.replace('Aml ', '')
        }
      })
    }
    const initialState = {
      title: 'CRR Details',
      type: "crr-break-points",
      crrGuidDetails: this.riskProfileData.riskFactor
    }
    this.bsModalRef = this.modalService.show(KycModalComponent, { initialState, class: 'modal-lg' });
  }

}
