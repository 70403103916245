import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { KycModalComponent } from '../../shared/kyc-modal/kyc-modal.component';
import { RiskScreeningService } from '../../../app/pages/risk-screening/risk-screening.service';

@Component({
  selector: 'app-risk-screening-table',
  templateUrl: './risk-screening-table.component.html',
  styleUrls: ['./risk-screening-table.component.scss','../../../../src/app/pages/risk-screening/risk-screening.component.scss']
})
export class RiskScreeningTableComponent implements OnChanges {
  @Input() data;
  @Input() entityType;
  @Input() subcategory;
  tags = [{ "slug": "pep", "name": "PEP", value: 'No Risk', entity: "Individual" }, { "slug": "crime", "name": "Crime", value: 'No Risk', entity: "Individual,Organisation,Entity" },
  { "slug": "sanction", "name": "Sanction", value: 'No Risk', entity: "Individual,Organisation,Entity" }, { "slug": "watchlist", "name": "Watchlist", value: 'No Risk', entity: "Individual,Organisation,Entity" },
  { "slug": "sip", "name": "SIP", value: 'No Risk', entity: "Individual" }, { "slug": "sie", "name": "SIE", value: 'No Risk', entity: "Organisation,Entity" }, 
  { "slug": "si", "name": "SI", value: 'No Risk', entity: "Individual,Organisation,Entity" }
  ];
  public bsModalRef: BsModalRef;
  constructor(private modalService: BsModalService, public _rs: RiskScreeningService) { }

  ngOnChanges(changes: SimpleChanges): void {
    let categoryList = [];
    if (changes['data']['currentValue']) {
      for (let item of this.data) {
        categoryList.push(item.slug);
      }
      this.tags = this.tags.map((m) => ({
        ...m,
        value: categoryList.includes(m.slug) ? this._rs.getTagValue(m.slug) : 'No Risk',
        color: categoryList.includes(m.slug) ? this._rs.getTagColor(m.slug) : '-',
      }));
    }
  }

  openModal(slug, category){
    const selectedCategory = this.subcategory.filter((f) => f.parentCategory.toLowerCase() == slug);
    const initialState = {
      title: `Subcategories`,
      type: "sub-category-data-list",
      subcategoryData : { 
        category : category, 
        selectedCategoryList: selectedCategory
      }
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md",
    });
  }

}
