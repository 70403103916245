import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService, DataService } from '../../../services';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-okyc-checks',
  templateUrl: './okyc-checks.component.html',
  styleUrls: ['./okyc-checks.component.scss']
})
export class OkycChecksComponent implements OnChanges {
  @Input() traversalState: any;
  @Input() usersCheckConfig: any;
  @Output() checkState = new EventEmitter<any>();
  aadhaarForm: FormGroup = this.form.group({
    docNumber: ['', [Validators.required, Validators.pattern("[0-9]{12}")]]
  });
  stepNumber: number = 1;
  isAadhaarValidate: boolean = false;
  otpLength = 6;
  otpConfig = {
    length : this.otpLength,
    allowNumbersOnly : true
  };
  errorMsg = '';
  otpForm : FormGroup = this.form.group({
    otp:['',Validators.required]
  });
  referenceNumber: any;
  okycGroupData: any;

  constructor(private form: FormBuilder, private _app: AppService, private _data: DataService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.traversalState?.currentValue){
      this.traversalState = changes?.traversalState?.currentValue;
    }
    if(changes?.usersCheckConfig?.currentValue){
      this.usersCheckConfig = changes?.usersCheckConfig?.currentValue;
    }
    const aadhaarData = this.usersCheckConfig.filter(obj => obj.standardCheckGroup == 'nid-okyc-check');
    if(aadhaarData?.length) this.okycGroupData = aadhaarData[0];
  }

  get aadhaarValidate(): { [key: string]: AbstractControl } {
    return this.aadhaarForm.controls;
  }

  sendOtp(){
    this.errorMsg='';
    this.isAadhaarValidate = true;
      if(this.aadhaarForm.valid){
        let payload={
          aadhaar_no : this.aadhaarForm.value.docNumber
        }
        this._data.changeLoaderVisibility(true);
        this._app.nidAadhaarSendOtp(payload, this.okycGroupData['endUserCheckID']).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res=>{
          if(res.data.statusCode==2200){
            this.referenceNumber = res.data.data.referenceId
            this.stepNumber = 2;
            this._data.toastrShow('OTP sent to your registered mobile number', "info");
          }
          else{
            this.goToStep('next');
            this._data.toastrShow(res.data.data.message, "error");
          }
        });
      }
  }

  resendOtp(){
    this.errorMsg='';
    let payload={
      aadhaar_no : this.aadhaarForm.value.docNumber
    }
    this._data.changeLoaderVisibility(true)
    this._app.nidAadhaarSendOtp(payload, this.okycGroupData['endUserCheckID']).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res=>{
      if(res.data.statusCode==2200){
        this.referenceNumber = res.data.data.referenceId
        this._data.toastrShow('OTP re-sent to your registered mobile number', "info");
      }
      else{
        this._data.toastrShow(res.data.data.message, "error");
      }
    });
  }

  submitOtp(){
    let payload={
      share_code: "2345",
      aadhaar_otp: this.otpForm.value['otp'],
      reference_id: this.referenceNumber
    }
    this._data.changeLoaderVisibility(true)
    this._app.nidAadhaarVerifyOtp(payload, this.okycGroupData['endUserCheckID']).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(res=>{
      if(res.data.statusCode==2202){
        this._data.toastrShow('Offline Aadhaar is successful', "success");
        this.goToStep('next');
      }
      else if(res.data.statusCode == 2203){
        this.errorMsg = res.data.data.message
      }
      else{
        this._data.toastrShow(res.data.data.message, "error");
      }
    });
  }

  onOtpChange(event){
    this.errorMsg='';
    if(event.length == this.otpLength){
      this.otpForm.get('otp').setValue(event);
    }
    else{
      this.otpForm.get('otp').setValue('');
    }
  }

  wrongAadhaar(){
    this.stepNumber = 1;
    this.otpForm.get('otp').setValue('');
  }

  goToStep(stepValue) {
    let state = this.traversalState.filter(obj => obj.standardCheckGroup == 'nid-okyc-check');
    if (state.length) {
      if (!state[0].isFinalStep) {
        let traversalState = {
          "allowedState": state[0],
          "goToStep": stepValue
        }
        this.checkState.emit(traversalState);
      } else {
        {
          let traversalState = {
            "allowedState": state[0],
            "goToStep": "isFinalStep"
          }
          this.checkState.emit(traversalState);
        }
      }
    }
  }

}
